﻿//JS for form elements, used globally and minified / concatenated into global.js

var isAce = document.querySelector('.ace-page');

/* Select Inputs: Adds class to select input container when the select input is clicked. Changes styles of custom dropdown on click.*/
var selectContainers = document.querySelectorAll('.select-container');
if (selectContainers) {
    [].forEach.call(selectContainers, function (selectContainer) {
        var select = selectContainer.querySelector('select');
        select.addEventListener('click', function () {
            selectContainer.classList.toggle('selected');
        });
        select.addEventListener('blur', function () {
            selectContainer.classList.remove('selected');
        });

        //Add height difference for select containers with and without labels within container
        var label = selectContainer.querySelector('label');
        if (label) {
            selectContainer.classList.add('containsLabel');
        }
    });
}

/* ACE Radio Inputs: Adds class to radio input <li> elements to style them like other radios (cannot change markup, have to add with JS)*/
if (isAce) {
    var deliveryMethodUL = document.querySelectorAll('.payment-delivery-method li');
    if (deliveryMethodUL) {
        [].forEach.call(deliveryMethodUL, function (li) {
            li.classList.add('radio-container');
        });
    }
}

/* Email Inputs: Inline form validation for email input types*/
var allEmails = document.querySelectorAll('input[type=email]');
[].forEach.call(allEmails, function (email) {
    email.addEventListener('blur', function () {
        if (email.checkValidity() === false) {
            email.classList.add('invalid');
        }
        if (email.checkValidity() === true) {
            email.classList.remove('invalid');
        }
    });
});

/* ACE 'Optional' Messaging for Non-Required Inputs and Selects: Gets label with matching 'for' / 'id' attributes, then adds 'label-optional' class to that label*/
function addOptional(element) {
    var nextEl = element.nextElementSibling;
    var parentEl = element.parentElement;
    var inputID = element.getAttribute('id');
    var labelForInput = document.querySelector('label[for=' + inputID + ']');
    if (nextEl && labelForInput) {
        var errorMsg = nextEl.classList.contains('errorMsg');
        if (!errorMsg && !nextEl.classList.contains('required') && !labelForInput.classList.contains('required')) {
            if (labelForInput) {
                labelForInput.classList.add('label-optional');
            }
        }
    } else {
        if (labelForInput) {
            labelForInput.classList.add('label-optional');
        }
    }
}

if (isAce) {
    let allOptionalInputs = document.querySelectorAll('.ace-page input:optional:not([type=radio]):not([type=checkbox])');
    var allOptionalSelects = document.querySelectorAll('.ace-page select:optional');
    [].forEach.call(allOptionalInputs, function (input) {
        addOptional(input);
    });
    [].forEach.call(allOptionalSelects, function (select) {
        addOptional(select);
    });
} else {
    let allOptionalInputs = document.querySelectorAll('input:optional:not([type=radio]):not([type=checkbox]):not(.footer-email-input)');
    [].forEach.call(allOptionalInputs, function (input) {
        var inputID = input.getAttribute('id');
        var labelForInput = document.querySelector('label[for=' + inputID + ']');
        if (labelForInput && !labelForInput.classList.contains('required')) {
            labelForInput.classList.add('label-optional');
        }
    });
}

/* ACE Inline Validation: Add validation containers to inline error messages */
if (isAce) {
    var allErrorMessages = document.querySelectorAll('.ace-page span.errorMsg');
    [].forEach.call(allErrorMessages, function (error) {
        var errorParent = error.parentElement;
        errorParent.classList.add('validation-container');
    });
}

/* ACE Inline Validation: Hack to override ACE inline style (display) for ACE register page validation spans (also for profile page)*/
function checkForInline() {
    var errorMsgs = document.querySelectorAll('.ace-page #registerControl span.errorMsg');
    [].forEach.call(errorMsgs, function (error) {
        var errorDisplay = error.style.display;
        if (errorDisplay === 'inline') {
            error.style.display = 'inline-block';
        }
    });
}

if (isAce) {
    var allInputs = document.querySelectorAll('.ace-page #registerControl input');
    [].forEach.call(allInputs, function (input) {
        if (input) {
            input.addEventListener('blur', checkForInline);
        }
    });
    var submitInput = document.querySelector('.ace-page #registerControl input[type=submit]');
    if (submitInput) {
        submitInput.addEventListener('click', checkForInline);
    }
    var updateInfoButton = document.querySelector('.ace-page #registerControl .update-info');
    if (updateInfoButton) {
        updateInfoButton.addEventListener('click', checkForInline);
    }
}