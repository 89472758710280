import { nodeListToArray } from '../helpers/nodeListToArray';
import { toggleDataAttribute } from '../helpers/toggleDataAttribute';
import { Tabs } from '../modules/tabs';
export interface ITabOptions {
    container?: HTMLElement;
}

export class MembershipTabs extends Tabs {
    membershipCode: HTMLInputElement;
    miniCartHeader: HTMLElement;
    miniCartTotal: HTMLElement;
    asideCheckboxes: [];
    digitalBenefits: [];
    upgradeCode: HTMLInputElement;
    taxDeductibleMessages: [];

    constructor(options: ITabOptions) {
        super(options);
        this.membershipCode = document.getElementById('membershipCode') as HTMLInputElement;
        this.miniCartHeader = document.querySelector('[data-membership-title]');
        this.miniCartTotal = document.querySelector('[data-membership-total]');
        this.digitalBenefits = nodeListToArray(document.querySelectorAll('[data-digital="true"][data-enabled="false"]'));
        this.taxDeductibleMessages = nodeListToArray(document.querySelectorAll('[data-tax-deductible]'));
        this.asideCheckboxes = nodeListToArray(document.querySelectorAll('[data-membership-aside] input[type=checkbox]'));
        this.upgradeCode = document.getElementById('upgradeCode') as HTMLInputElement;
        // Set active panel based on various factors
        this.setActiveTabPanel();

        if (this.asideCheckboxes) {
            this.handleDeclinedBenefits();
        }

        if (this.upgradeCode) {
            this.handleUpgrade();
        }

    }

    // Activates any given tab panel
    activateTab(tab, setFocus) {
        setFocus = setFocus || true;
        // Deactivate all other tabs
        this.deactivateTabs();

        // Remove tabindex attribute
        tab.removeAttribute('tabindex');

        // Set the tab as selected
        tab.setAttribute('aria-selected', 'true');

        // Get the value of aria-controls (which is an ID)
        const controls = tab.getAttribute('aria-controls');

        // Remove hidden attribute from tab panel to make it visible
        document.getElementById(controls).removeAttribute('hidden');


        // Set focus when required
        if (setFocus) {
            tab.focus();
        }

        if (this.tabSwitchHandlers) {
            this.tabSwitchHandlers.forEach(handler => {
                setTimeout(handler.bind(this), 0);
            })
        }


        if (this.membershipCode) {
            this.membershipCode.value = tab.getAttribute('data-membership-code');

        }

        this.miniCartHeader.innerHTML = tab.getAttribute('data-membership-name');
        this.miniCartTotal.innerHTML = tab.getAttribute('data-membership-price');
        this.handleTaxMessage(tab.getAttribute("data-membership-code"));

        this.asideCheckboxes.forEach((input: HTMLInputElement) => {
            input.checked = false;
        });

    }


    setActiveTabPanel() {
        // Check for query string presence, check for featured level attr, default to 1st item
        const params = new URLSearchParams(window.location.search)
        const renewalLevel = this.membershipCode?.value;

        if (renewalLevel) {
            this.activateTab(document.querySelector(`[aria-controls=${renewalLevel}]`), false)
        }
        else if (params.has("levelId")) {
            let queryId = params.get('levelId').toUpperCase() as string;
            this.activateTab(document.querySelector(`[aria-controls=${queryId}]`), false)
        }
        else if (document.querySelector('[data-featured-level="True"]')) {
            this.activateTab(document.querySelector('[data-featured-level="True"]'), false)
        }
        else {
            this.activateTab(this.tabs[0], false)
        }
    }
    handleDeclinedBenefits() {
        const declinedBenefits = document.querySelector('#declineBenefits') as HTMLInputElement;

        this.asideCheckboxes.forEach((input: HTMLInputElement) => {
            input.addEventListener('change', () => {
                if (input.checked) {
                    declinedBenefits.value = "true";
                }
                else {
                    declinedBenefits.value = "false";
                }
            })
        })
    }
    handleUpgrade() {
        const renewCurrentBtns = nodeListToArray(document.querySelectorAll('[data-renewal-button]'));
        renewCurrentBtns.forEach((button: HTMLButtonElement) => {
            button.addEventListener('click', () => {
                this.activateTab(document.querySelector(`[aria-controls=${button.value}]`), false)
                document.querySelector('[data-membership-page]').scrollIntoView({ behavior: 'smooth' })
            })
        })
    }

    handleTaxMessage(code) {
        this.taxDeductibleMessages.forEach((message: HTMLElement) => {
            message.setAttribute('aria-hidden', 'true')
        })

        document.querySelector(`[data-tax-deductible=${code}`)?.setAttribute('aria-hidden', 'false')
    }
}



