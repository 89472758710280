import isTouchDevice from '../../helpers/is-touch';

export class mainNavigation {
    hideClass: string;
    visibleClass: string;
    isCollapsed: boolean;
    headerElement: HTMLElement;
    headerHeight: number;
    lastPos: number;
    curPos: number;
    delta: number;
    lastDelta: number;
    nav: HTMLElement;
    navContainer: HTMLElement;
    setForTouchscreen: Function;
    activeLink: HTMLElement;
    topNavs: Array<any>;

    constructor(navigation: HTMLElement) {
        this.hideClass = 'collapsed';
        this.visibleClass = 'visible';
        this.isCollapsed = false;
        this.headerElement = document.querySelector('section') as HTMLElement; //first section on page
        this.nav = navigation;
        this.navContainer = document.querySelector('header') as HTMLElement;
        this.activeLink = null;
        this.topNavs = Array.prototype.slice.call(this.nav.querySelectorAll('.nav-item-li0.has-children'));

        //setup callback function for if the device is a touch screen - 
        //first click just focuses to drop down the nav, 2nd click goes through
        this.setForTouchscreen = () => {
          this.topNavs.forEach((link, index) => {
            let anchor = link.querySelector('a');
            anchor.addEventListener('click', e => {
              e.preventDefault();
              if (anchor === this.activeLink) {
                window.location.href = anchor.href;
              }
              else {
                this.activeLink = anchor;
                link.classList.add('focused');
              }
            })
          })
        }
  
        //deal with focus/keyboards     
        this.topNavs.forEach((topNav) => {
            let childTags = Array.prototype.slice.call(topNav.querySelectorAll('a'));

            childTags.forEach((childTag) => {
                childTag.addEventListener('focus', () => {
                    if (!topNav.classList.contains('focused')) {
                        topNav.classList.add('focused');
                       if (topNav.querySelector('.meganav')) {
                        topNav.querySelector('.meganav').firstElementChild.focus(); 
                        topNav.querySelector('.meganav').setAttribute('aria-hidden', 'false');
                       }
                    }
                 
                });
                childTag.addEventListener('blur', () => {
                    if (!topNav.contains(document.activeElement)) {
                        topNav.classList.remove('focused');
                        if (topNav.querySelector('.meganav')) {
                            topNav.querySelector('.meganav').setAttribute('aria-hidden', 'true');
                           }
                    }
                });
            });
		});		
    }


	setSubnavHeights() {
        //smoother animated dropdowns
        this.topNavs.forEach(topNav => {
			let subNav = topNav.querySelector('.nav-main-ul1');
			subNav.classList.add('measuring');
			subNav.style.height = subNav.clientHeight + 'px';
			subNav.classList.remove('measuring');
        })
	}

    checkPos() {
        this.curPos = window.pageYOffset;
        this.delta = this.curPos - this.lastPos;
        this.lastPos = this.curPos;
        if (!this.isCollapsed) {
            if ((this.delta > 0 && this.lastDelta > 0 && this.curPos > 19) || document.body.classList.contains('fixed')) {
                this.navContainer.classList.add(this.hideClass);
                this.isCollapsed = true;
                this.navContainer.classList.remove(this.visibleClass);
            }
        }
        else if (((this.delta < 0 && this.lastDelta < 0) || this.curPos < 20) && !document.body.classList.contains('fixed')) {
            this.navContainer.classList.remove(this.hideClass);
            this.isCollapsed = false;
            setTimeout(() => {
                this.navContainer.classList.add(this.visibleClass);
            }, 300);
        }
   
        this.lastDelta = this.delta;
	};  
}