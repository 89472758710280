//This file is for general global JS, similar to screen.scss. The files in global.min.js are loaded on every page on the site.


/* Global vars*/
var isAce = document.querySelector('.ace-page');

if (document.querySelector('#react-cart-timer')) {
    var isAceHeading = isAce.querySelector('.page-heading');
    isAceHeading.classList.add('cart-heading-timer');
}

/* Mobile Safari hack: allow 'active' styles to show on links/buttons when touched*/
document.addEventListener('touchstart', function () { }, true);

/* Browser hacks for Safari and iOS */
var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
var isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;


if (isIE11) {
    document.body.classList.add('isIE11');
}

if (isiOS) {
    window.addEventListener('load', function () {
        document.body.classList.add('isiOS');
    });
}

/* ACE Modal: Functionality to close modal when clicking background element*/
function removeModal(modalCancelParam) {
    window.addEventListener('load', function () {
        if (isAce) {
            var modalCancel = document.querySelectorAll(modalCancelParam);
            var modalBG = document.querySelectorAll('.bgOverlay');
            if (modalCancel) {
                modalBG.forEach(function (bg) {
                    bg.addEventListener('click', function () {
                        modalCancel.forEach(function (cancel) {
                            cancel.click();
                        });
                    });
                });
            }
        }
    });
}
removeModal('.simpleModal input[value=Cancel]');
removeModal('.selectNewSectionContainer .btnCancel');

/* Audio Block: Play/pause functionality, timing functionality*/
var audioBlocks = document.querySelectorAll('.fiftyfifty-audio');

function readableDuration(seconds) { //audio helper
    var sec = Math.floor(seconds);
    var min = Math.floor(sec / 60);
    min = min >= 10 ? min : '0' + min;
    sec = Math.floor(sec % 60);
    sec = sec >= 10 ? sec : '0' + sec;
    return min + ':' + sec;
}

function setTimer(audio, audioTimeEl) { //audio helper
    var audioDuration = readableDuration(audio.duration);
    audioTimeEl.innerHTML = audioDuration;
}
[].forEach.call(audioBlocks, function (audioBlock) {
    if (audioBlock) {
        //create player
        var audio = new Audio();
        audio.src = audioBlock.getAttribute('data-audioSrc');
        //set timer initially, progress bar
        var audioTimeEl = audioBlock.querySelector('.fiftyfifty-audio-time');
        var progressBar = audioBlock.querySelector('progress');
        audio.addEventListener('loadedmetadata', function () {
            setTimer(audio, audioTimeEl);
            progressBar.setAttribute('max', audio.duration);
        });
        //play and pause
        var audioPlayBtn = audioBlock.querySelector('.fiftyfifty-audio-play');
        var audioPauseBtn = audioBlock.querySelector('.fiftyfifty-audio-pause');
        audioPlayBtn.addEventListener('click', function () {
            [].forEach.call(audioBlocks, function (block) {
                var otherPauseBtns = block.querySelector('.fiftyfifty-audio-pause');
                otherPauseBtns.click();
            });
            audio.play();
            audioPlayBtn.classList.add('hide');
            audioPauseBtn.classList.remove('hide');
        });
        audioPauseBtn.addEventListener('click', function () {
            audio.pause();
            audioPauseBtn.classList.add('hide');
            audioPlayBtn.classList.remove('hide');
        });
        //countdown
        audio.addEventListener('timeupdate', function () {
            audioTimeEl.innerHTML = readableDuration(audio.duration - audio.currentTime);
            //progress bar updating as audio plays
            progressBar.setAttribute('value', audio.currentTime);
            if (audio.currentTime === audio.duration) { //if audio is done playing
                audio.currentTime = 0;
                progressBar.setAttribute('value', audio.currentTime);
                audioPauseBtn.click();
            }
        });
        function updateProgressValue(e) {
            var outOfTotal = e.offsetX;
            var totalWidth = e.target.clientWidth;
            var clickPercentage = (outOfTotal / totalWidth);
            var newValue = (clickPercentage * audio.duration);
            progressBar.value = newValue;
            audio.currentTime = newValue;
        }
        //click timeline to update progress bar
        progressBar.addEventListener('click', function (e) {
            updateProgressValue(e);
        });
        //the following events are necessary for dragging the progress bar
        progressBar.addEventListener('mousedown', function () {
            progressBar.addEventListener('mousemove', updateProgressValue);
        });
        progressBar.addEventListener('mouseup', function () {
            progressBar.removeEventListener('mousemove', updateProgressValue);
        });
        progressBar.addEventListener('mouseout', function () {
            progressBar.removeEventListener('mousemove', updateProgressValue);
        });
    }
});

/* Callout Quarter Block: Add class for special stacking*/
var fiftyfifty = document.querySelectorAll('.fiftyfifty:not(.fiftyfifty-stacked)');
[].forEach.call(fiftyfifty, function (fifty) {
    if (fifty) {
        var innerFifty = fifty.querySelector('.fiftyfifty');
        if (innerFifty) {
            var calloutQuarter = innerFifty.querySelector('.callout-quarter');
            if (calloutQuarter) {
                fifty.classList.add('fiftyfifty-callout-quarter');
            }
        }
    }
});

/*Callout Interactions: Functionality for scrolling interactions*/
var calloutHalf;
var windowHeight;
var calloutQuarterBlock;

var calloutQuarter = document.querySelectorAll('.fiftyfifty-callout-quarter');
[].forEach.call(calloutQuarter, function (block) {
    if (block) {
        calloutQuarterBlock = block;
        calloutHalf = block.querySelector('.callout-half');
        windowHeight = window.innerHeight;
        document.addEventListener('scroll', function () {
            requestAnimationFrame(calloutInteractions);
        });
    }
});

function calloutInteractions() {
    if (calloutHalf) {
        var topOfDiv = calloutHalf.getBoundingClientRect().top;
        var bottomOfDiv = calloutHalf.getBoundingClientRect().bottom;//how far bottom of div is from top of window
        var pixelsFromBottomofWindow = bottomOfDiv - windowHeight;
        var wholeBlock = calloutQuarterBlock.getBoundingClientRect().bottom - windowHeight;
        var wholeBlockTop = calloutQuarterBlock.getBoundingClientRect().top;
        //end of elements, scrolling down
        if (pixelsFromBottomofWindow <= 0 && wholeBlock > 0) {
            calloutHalf.classList.add('fixed');
            calloutHalf.classList.add('bottom');
            calloutHalf.classList.remove('top');
        }
        if (wholeBlock <= 0) {
            calloutHalf.classList.remove('fixed');
            calloutHalf.classList.add('bottom');
            calloutHalf.classList.remove('top');
        }
        //top of elements, scrolling up
        if (topOfDiv > 0 && wholeBlockTop < 0) {
            calloutHalf.classList.add('fixed');
            calloutHalf.classList.add('top');
            calloutHalf.classList.remove('bottom');
        }
        if (wholeBlockTop >= 0) {
            calloutHalf.classList.remove('fixed');
            calloutHalf.classList.add('top');
            calloutHalf.classList.remove('bottom');
        }
    }
}

/* Overview Block: Toggle hidden content*/
var overviewBlock = document.querySelectorAll('.overview-block');
if (overviewBlock) {
    [].forEach.call(overviewBlock, function (block) {
        let button = block.querySelector('.overview-block-item-button');
        if (button) {
            button.addEventListener('click', function () {
                //additional text visibility
                block.classList.toggle('show-more');

                //button text updates
                if (block.classList.contains('show-more')) {
                    button.innerText = button.getAttribute('data-show-less-text');
                } else {
                    button.innerText = button.getAttribute('data-show-more-text');
                }
            });
        }
    });
}


/* Callout Fullwidth YouTube videos - when video ends, change behavior */
var allYouTubeVideos = document.querySelectorAll('.video-foreground');
if (allYouTubeVideos) {
    var tag = document.createElement('script');
    tag.id = 'iframe-demo';
    tag.src = 'https://www.youtube.com/iframe_api';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}