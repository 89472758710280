export class privacyAlert {
	acceptButton: HTMLElement;
	container: HTMLElement;

	constructor(container: HTMLElement) {
		this.container = container;
		this.acceptButton = container.querySelector('[data-privacy-accept]');
		this.initListeners();
	}

	initListeners() {

		this.acceptButton.addEventListener('click', () => {
			this.hideAlert();
			this.setCookie('SFJAZZ_Cookie_Accepted', 'True', 365);
		});

	}

	hideAlert() {
		this.container.classList.add('hidden');
	}

	setCookie(name, value, days) {
		var expires = "";
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			expires = "; expires=" + date.toUTCString();
		}
		document.cookie = name + "=" + (value || "") + expires + "; path=/";
	}
}