// Resize Sensor will ensure the sticky sidebar is able to update itself when the browser is resized

import * as StickySidebar from 'sticky-sidebar/dist/sticky-sidebar.js';
import '../../../node_modules/css-element-queries/src/resizesensor.js';

export class StickyContainer {
    topMargin: number;
    bottomMargin: number;
    hasImage: boolean;
    breakpoint: number;
    stickySidebar: any;
    navContainer: HTMLElement;
    image: HTMLElement;
    accountForNavigation: boolean;
    sidebar: HTMLElement;
    initialSidebarHeight: number;
    constructor(sidebar: HTMLElement) {
        this.topMargin = parseInt(sidebar.getAttribute('data-margin-top'));
        this.bottomMargin = parseInt(sidebar.getAttribute('data-margin-bottom'));
        this.breakpoint = parseInt(sidebar.getAttribute('data-sticky-for'));
        this.updateStickySidebar = this.updateStickySidebar.bind(this);
        this.navContainer = document.getElementById('mheader');
        this.hasImage = sidebar.hasAttribute('data-has-image');
        this.image = sidebar.querySelector('img');
        this.accountForNavigation = false;
        this.sidebar = sidebar;
        this.initialSidebarHeight = this.sidebar.querySelector("[data-sticky-inner]").getBoundingClientRect().height;
        this.stickySidebar = new StickySidebar(
            `#${this.sidebar.id}`,
            {
                containerSelector: '[data-sticky-container]',
                innerWrapperSelector: '[data-sticky-inner]',
                topSpacing: this.topMargin,
                bottomSpacing: this.bottomMargin,
                minWidth: this.breakpoint,
                image: this.image
            }
        );

        if (this.hasImage) {

            this.image.addEventListener('load', () => {
                this.updateStickySidebar()
            })

        }

        // Update it after a beat, in case anything needs to finish loading
        window.requestAnimationFrame(() => {
            this.updateStickySidebar();
        });

    }

    updateStickySidebar() {
        if (this.stickySidebar) {
            this.stickySidebar.updateSticky();
        }
    }



}
