import Swiper, { Navigation } from 'swiper';

export class BasicCarousel {

    constructor(element: HTMLElement) {
        let carouselInstance;
        let activeClass = 'carousel-active';
        function initCarousel(container) {
            carouselInstance = new Swiper(element, {
                slidesPerView: 1.1,
                spaceBetween: 10,
                modules: [Navigation],
                navigation: {
                    nextEl: '.full-carousel-arrow-next',
                    prevEl: '.full-carousel-arrow-prev',
                },
                breakpoints: {
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 15
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 30
                    },

                },
                speed: 500,
                autoHeight: false,
                loop: false,
            })
        }

        function initHandler(container) {
            if (!element.classList.contains(activeClass)) {
                initCarousel(container);
                element.classList.add(activeClass);
            }
        }

        initHandler(element);
    }
}