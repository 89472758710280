import { nodeListToArray } from '../helpers/nodeListToArray';

export class headerSearch {
    searchContainer: HTMLElement;
    searchInput: any;
    searchSubmit: HTMLElement;
    searchbarTrigger: HTMLElement;
    searchActive: boolean;
    header: HTMLElement;
    constructor(searchContainer) {
        this.searchInput = searchContainer.querySelector('[data-search-input]');
        this.searchSubmit = searchContainer.querySelector('[data-search-submit]');
        this.searchbarTrigger = document.querySelector('[data-searchbar-trigger]');
        this.searchActive = false;
        this.searchContainer = searchContainer;
        this.header = document.querySelector('header');

        this.searchbarTrigger.addEventListener('click', (e) => {
            this.searchActive = !this.searchActive;
            this.toggleActiveSearch();
        });

        this.searchSubmit.addEventListener('click', (e) => {
            e.preventDefault();
            this.initSearch(this.searchInput)
        });

        const submitListener = (e: KeyboardEvent) => {
            if (e.key === "Enter" && (this.searchInput === document.activeElement)) {
                e.preventDefault();
                this.initSearch(this.searchInput);
            }
        };

        window.addEventListener('keydown', submitListener);
    }

    initSearch(searchInput) {
        let searchFieldValue = searchInput.value;
        let siteUrlValue = window.location.host;
        if (searchFieldValue !== "") {
            let redirectUrl: any = `http://${siteUrlValue}/search?q=${searchFieldValue}`;
            (window as any).location.href = redirectUrl;
        }
    }

    toggleActiveSearch() {
        if (this.searchActive) {
            this.searchContainer.setAttribute('aria-hidden', 'false');
            document.body.classList.add('search-active');
            if (this.header.classList.contains('collapsed')) {
                this.header.classList.remove('collapsed');
                this.header.classList.add('visible');
            }
        }
        else {
            this.searchContainer.setAttribute('aria-hidden', 'true');
            document.body.classList.remove('search-active');
        }
    }
 
}

