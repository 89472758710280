import { Carousel } from '../sfj/specific/callout-carousel'
import { SponsorCarousel } from '../sfj/specific/sponsor-carousel'
import { ShelfCarousel } from '../sfj/specific/shelf-carousel'
import { nodeListToArray } from '../helpers/nodeListToArray'
import { BasicCarousel } from '../sfj/specific/basic-carousel';

(function () {

    // Gallery Init
    let galleryClass = 'sfjazz-gallery';
    let galleries = nodeListToArray(document.querySelectorAll('[data-gallery]'));

    galleries.forEach((gallery, index) => {
        gallery.classList.add(`${galleryClass}-${index}`);

        new Carousel({
            containerClass: `${galleryClass}-${index}`,
            slidesPerView: 1,
        })

    });

    // Sponsor Carousel Init
    const sponsorBlocks =  nodeListToArray(document.querySelectorAll('[data-sponsor-carousel]')) as HTMLElement[];

    sponsorBlocks.forEach(() => {
        sponsorBlocks.forEach(carousel => {
            new SponsorCarousel(carousel);
        });

    });

    const shelfGalleriesInit = () => {
        // Hero carousel
        const homeCarousels = nodeListToArray(document.querySelectorAll('[data-events-gallery]')) as HTMLElement[];
        homeCarousels.forEach(carousel => {
            new ShelfCarousel(carousel);
        });
    }

    shelfGalleriesInit();

    // Sponsor Carousel Init
    const globalGalleries = nodeListToArray(document.querySelectorAll('[data-global-carousel]')) as HTMLElement[];

    globalGalleries.forEach(() => {
        globalGalleries.forEach(carousel => {
            new BasicCarousel(carousel);
        });

    });

})();
