import isTouchDevice from '../helpers/is-touch';
import { debounce } from 'lodash';

export class stickyHeader {
    hideClass: string;
    visibleClass: string;
    isCollapsed: boolean;
    headerElement: HTMLElement;
    headerHeight: number;
    lastPos: number;
    curPos: number;
    delta: number;
    lastDelta: number;
    nav: HTMLElement;
    navContainer: HTMLElement;
    activeLink: HTMLElement;
    mainNav: HTMLElement;
    subNav: HTMLElement;

    constructor(navigation: HTMLElement) {
        this.hideClass = 'collapsed';
        this.visibleClass = 'visible';
        this.isCollapsed = false;
        this.headerElement = document.querySelector('main > div') as HTMLElement; //first section on page
        this.nav = navigation;
        this.navContainer = document.querySelector('header') as HTMLElement;
        this.activeLink = null;
        this.mainNav = document.querySelector('[data-main-header]')
        this.subNav = document.querySelector('[data-sticky-header]')
        this.setNavClearance();



        window.addEventListener(
            'resize',
            debounce(() => {
                this.setNavClearance();
            }, 10)
        );
    }

    initPos() {
        if (this.headerElement) {
            this.headerHeight = Math.min((this.headerElement.getBoundingClientRect().bottom + window.pageYOffset), 500);
        }
            this.curPos = window.pageYOffset;
            this.lastPos = this.curPos;
            this.checkPos();
    }

    checkPos() {
        this.curPos = window.pageYOffset;
        this.delta = this.curPos - this.lastPos;
        this.lastPos = this.curPos;
        if (!this.isCollapsed && !document.body.classList.contains('nav-expanded')) {
            if ((this.delta > 0 && this.lastDelta > 0 && this.curPos > 19) || document.body.classList.contains('fixed')) {
                this.navContainer.classList.add(this.hideClass);
                this.isCollapsed = true;
                this.navContainer.classList.remove(this.visibleClass);
            }
        }
        else if (((this.delta < 0 && this.lastDelta < 0) || this.curPos < 20) && !document.body.classList.contains('fixed')) {
            this.navContainer.classList.remove(this.hideClass);
            this.isCollapsed = false;
            setTimeout(() => {
                this.navContainer.classList.add(this.visibleClass);
            }, 300);
        }
        this.setNavClearance();
        this.lastDelta = this.delta;

    }

    setNavClearance() {
        document.documentElement.style.setProperty('--fixed-header-clearance', `${this.mainNav.offsetHeight}px`);
        if (this.isCollapsed) {
            document.documentElement.style.setProperty('--nav-clearance', `${this.mainNav.offsetHeight}px`);
        }
        else {
            document.documentElement.style.setProperty('--nav-clearance', `${this.mainNav.offsetHeight + this.subNav.offsetHeight}px`);
        }
    }




}