import { nodeListToArray } from '../helpers/nodeListToArray';

export const customAmountInput = document.querySelector('[data-donation-custom]') as HTMLInputElement;
export const quickDonate = () => {

    if (customAmountInput) {
        const donationAmountsList = nodeListToArray(document.querySelectorAll('[data-donation-btns]'));

        let allBtns = [];

        if (donationAmountsList) {
            donationAmountsList.forEach((list) => {
                allBtns = [].slice.call(list.querySelectorAll('button[type="button"]'));
                allBtns.forEach(btn => {
                    btn.addEventListener('click', () => {
                        customAmountInput.value = btn.innerText;
                    })
                })
            })
        }

    }
}
