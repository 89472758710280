import Swiper, { Pagination } from 'swiper';
import { debounce } from '../../helpers/debounce';

export class SponsorCarousel {

    constructor(element: HTMLElement) {
        let carouselInstance;
        let activeClass = 'carousel-active';
        let breakpoint = '(min-width: 800px)';

        function initCarousel(container) {
            carouselInstance = new Swiper(element, { 
                slidesPerView: 5,
                slidesPerGroup: 5,
                spaceBetween: 0,
                modules: [Pagination],
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                },
                speed: 500,
                autoHeight: false,
                loop: false,
            })

        }

        // Inits based on screen size
        function initHandler(container) {
            if (window.matchMedia(breakpoint).matches) {
                if (!element.classList.contains(activeClass)) {
                    initCarousel(container);
                    element.classList.add(activeClass);
                }
            } else {
                destroyCarousel(container);
                element.classList.remove(activeClass);
            }
        }

        function destroyCarousel(container) {
            if (carouselInstance !== undefined) carouselInstance.destroy(true, true);
        }

        const resizeWatcher = debounce(() => {
            initHandler(element);
        }, 50);

        initHandler(element);
        window.addEventListener('resize', resizeWatcher);
    }
}